import React, {useContext} from "react";
import UserContext from "./Context"
import logo from "./logo.png"
export default function LogTop(props) {

    const user = useContext(UserContext)



    return (


        <>

        <div className="sticky z-50 top-0 left-0 h-[10vh] w-full">
            <div className="w-full h-full navbar bg-base-200">
            <div className="flex-1">
                <div className="btn btn-ghost">
                <a href="/" className="font-1 text-2xl flex flex-row items-center gap-2"><img src={logo} className="w-6" alt="Logo" />sniphomes</a>
                </div>
                

            </div>

            <div className="flex flex-row items-center gap-2 px-4">
                


                <div className="">
                    
                
                </div>
                <div className="cursor-pointer">
                <div className="dropdown dropdown-end">
        <div tabIndex={0} role="button" className="flex flex-row items-center gap-2"><div className="avatar placeholder  bg-base-300 rounded-full">
                        <div className="w-10 font-1">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4">
                            <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clipRule="evenodd" />
                    </svg>


                    </div>

                    

                </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-3">
  <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clipRule="evenodd" />
</svg></div>
        <ul
          tabIndex={0}
          className="menu dropdown-content bg-base-100 rounded-box z-[1] mt-4 w-52 p-2 shadow">
          <li className="font-3 font-bold" onClick={() => props.setMainNav("Settings")}><a>Settings</a></li>
          {/* <li></li> */}
        </ul>
      </div>
    </div>

                

         

            </div>
            

            </div>
            

        </div>





        </>
    )
}