import React from "react";
import Index from "./Index"
import Main from "./Main"
import Login from "./Login"
import Signup from "./Signup"
import CampaignDash from "./CampaignDash"
import Terms from "./Terms"
import ForgotPassword from "./ForgotPassword";
import {BrowserRouter, Routes,Route} from "react-router-dom"
import PrivacyPolicy from "./PrivacyPolicy";


function App(props) {




    return (
    
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Index />} />
                <Route path="/main" element={<Main />} />
                <Route path="/login" element={<Login />} /> 
                <Route path="/signup" element={<Signup />} />
                <Route path="/forgotPassword" element={<ForgotPassword />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />

            </Routes>
        </BrowserRouter>
    
)
}



export default App;