import React, {useEffect, useState} from "react";

// This component is for warning messages


export default function Message(props) {

    const [showMessage, setShowMessage] = useState(true)


    // useEffect(() => {

    //     setShowMessage(true)


    //     setTimeout(() => {
    //         setShowMessage(false)
    //     }, 5000)
    // }, [props.message])

    
    





    return (
        <>
        {(showMessage) && (
            <section className="font-3 text-base-100">

            {props.type === "err" ? 

                <div className="absolute left-[50%] bg-error top-0 w-full translate-x-[-50%] p-4 z-50 text-center">


                
                
<div>
    {props.error.trim()}


</div>






</div> : 


<div className="absolute left-[50%] bg-success top-0 w-full translate-x-[-50%] p-4 z-50 text-center">


                
                
                <div>
                    {props.error.trim()}


                </div>






            </div>
            }
            









        </section>

        )}
        






        </>
    )
}