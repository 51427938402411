import React, {useState, useEffect} from "react";
import Navbar from "./Navbar"
import Footer from "./Footer"
import callApi from "./CallApi"
import Message from "./Message"
import Loading from "./Loading"
import { Helmet } from "react-helmet" 

let timeout;
export default function ForgotPassword(props) {
    const [email, setEmail] = useState("")
    const [code, setCode] = useState("")
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState("")
    const [statusMessage, setStatusMessage] = useState({message: "", type:""})
    const [slider, setSlider] = useState(0)

    useEffect(() => {

        setTimeout(() => {
            if (statusMessage.message !== "") {
                timeout = setStatusMessage({message: "", type:""})
            }
        }, 5000)
        
        

    }, [statusMessage])



    const sendEmail = () => {
        setLoading(true)
        if ((email.split("@").length === 2) && (email.indexOf(".") !== -1)) {
            callApi("/forgotPassword", "POST", {email: email}).then((res) => {
                if (res.message === "invalid request") {
                    setStatusMessage({message: "Invalid request", type:"err"})
                } else if (res.code === "err") {
                    setStatusMessage({message: "Something went wrong", type: "err"})
                } else if (res.code === "ok") {
                    setSlider(1)
                }
            })
            setLoading(false)
        } else {
            setStatusMessage({message: "Enter a valid email address", type:"err"})
            setLoading(false)
        }
    }



    const sendCode = () => {
        try {
            const localCode = Number(code)
            if ((code.length > 0) && (password.length < 40) && (password.length>0)) {
                setLoading(true)
                callApi("/updatePassword", "POST", {password: password, email: email, code: localCode}).then((res) => {
                    if (res.code === "err") {
                        setStatusMessage({message: "Invalid code", type:"err"})
                    } else if (res.code === "ok") {
                        setStatusMessage({message: "Password changed", type: "ok"})
                        window.location.replace("/login")
                    } else {
                        setStatusMessage({message: "Something went wrong", type:"err"})
                    }
                    setLoading(false)
                })
    
                
    
    
    
            } else {
                setStatusMessage({message: "Please enter a code", type:"err"})
            }
        } catch(e) {
            setStatusMessage({message: "Invalid code", type:"err"})
        }
        
    }

    return (

        <>


<Helmet>
            <meta charSet="utf-8" />
            <title>Forgot Password • Sniphomes</title>
            <meta name="keywords" content="Real Estate, Leads, Lead Generation, Finding willing buyers, finding willing sellers, sniphome, sniphomes, snip homes, snip home, forgot, forgot password, password" />
            <meta
      name="description"
      content="Forgot your Password? The better way to generate real estate leads. Sniphomes uses cold calling technology to automate the lead generation process."
    />
        </Helmet>


        <Navbar />
        {(loading) && (
            <Loading />
        )}
        <div className="relative">
        {(statusMessage.message !== "") && (
                <Message error={statusMessage.message} type={statusMessage.type} />
        )}

        </div>

        <div className="w-full h-[75vh]">
            

        

            <div className="lg:w-2/6 md:w-3/6 w-full flex-col items-start min-w-fit mx-auto sm:min-h-0 min-h-[60vh] mt-24 bg-base-200 sm:p-10 p-2">
                <div className="">
                    <h1 className="text-3xl font-bold font-3">Reset your password</h1>
                    <p className="font-1 my-1">Don't worry, we all forget our passwords</p>
                </div>

                {(slider === 0) && (
                    <div className="flex-col flex gap-4">
                        <div className="flex flex-row gap-8 items-center font-3 my-1">
                            <p className="font-bold">Email:</p>
                            <input value={email} maxLength="100" onChange={(e) => {setEmail(e.target.value)}} className="input input-primary" placeholder="example@email.com" />
                        </div>

                        <btn onClick={sendEmail} className="btn btn-primary font-3 font-semibold text-lg">Send email</btn>
                    </div>
                )}
                {(slider === 1) && (
                    <div className="flex flex-col gap-4">

                        <div className="flex flex-row gap-8 items-center font-3 my-1">
                            <p className="font-bold">Code:</p>
                            <input value={code} maxLength="100" onChange={(e) => {setCode(e.target.value)}} className="input input-primary" placeholder="xxxxxxx" />
                        </div>
                        <div className="flex flex-row gap-8 items-center font-3 my-1">
                            <p className="font-bold">Password:</p>
                            <input type="password" value={password} maxLength="100" onChange={(e) => {setPassword(e.target.value)}} className="input input-primary" placeholder="xxxxxxx" />
                        </div>

                        <btn onClick={sendCode} className="btn btn-primary font-3 font-semibold text-lg">Change Password</btn>

                    </div>
                )}
                


            </div>
            
        </div>


        <Footer />




        </>
    )
}