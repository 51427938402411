import React, {useContext, useState, useEffect} from "react";
import UserContext from "./Context"
import callApi from "./CallApi"
import Loading from "./Loading"
import Message from "./Message"


export default function Settings(props) {
    
    
    const customerPortalLink = "https://billing.stripe.com/p/login/cN2eYf8wO2CTaXe7ss"

    const [user,setUser] = useState(useContext(UserContext))
    const [operatingInput, setOperatingInput] = useState("")
    const [aiInput, setAiInput] = useState(user.aiSettings.name)
    const [loading, setLoading] = useState(false)
    const [statusMessage, setStatusMessage] = useState({message: "", type:""})

    useEffect(() => {

        setTimeout(() => {
            if (statusMessage.message !== "") {
                setStatusMessage({message: "", type:""})
            }
        }, 5000)
        
        

    }, [statusMessage])


    const changeUser = () => {

        if (aiInput.length > 0) {
            setUser((prevUser) => {
                return {
                    ...prevUser,
                    aiSettings: {
                        ...prevUser.aiSettings,
                        name: aiInput,
                    }

                }
            })
            setLoading(true)
            callApi("/updateUser", "POST", {aiName: aiInput, operatingArea: user.operatingArea}).then((res) => {
                if (res.message === "user not found") {
                    setStatusMessage({message: "User not found", type:"err"})
                    setLoading(false);
                    window.location.replace("/login")
                } else if (res.code === "err") {
                    setStatusMessage({message: "Something went wrong", type:"err"})
                    setLoading(false);
                    console.log("Heres the thing though")
                } else if (res.code === "ok") {
                    props.setUser(user)
                    setStatusMessage({message: "Successfully updated my user", type:"ok"})
                    setLoading(false);
                    console.log("Hi wsg")
                } else {
                    setStatusMessage({message: "Something went wrong", type:"err"})
                    setLoading(true);
                    console.log("type shit inna bit")
                }
            })








        } else { 
            console.log("sdf")
        }


    
        


        

    }








    return (
        <>

            <div className="bg-base-100 font-3 w-full p-4 min-h-[90vh] relative">
                {(loading) && (
                    <Loading />
                )}
                {(statusMessage.message !== "") && (
                    <Message error={statusMessage.message} type={statusMessage.type} />
                )}
                <p className="font-3 font-bold text-2xl">Settings:</p>
                <div className="p-3 mt-2 bg-neutral">
                    <p className="text-lg font-bold w-full p-2 bg-base-300">Basic Information</p>
                    <div className="grid sm:grid-cols-2 grid-cols-1">
                    <div className="flex flex-col mt-2 bg-base-100">
                        
                    
                        <div className="flex flex-row items-center gap-4 bg-base-100 w-fit p-3">
                            <p className="font-semibold">Name:</p>
                            <input className="input bg-base-300 font-semibold" disabled value={user.name} aria-disabled="true" />
                        </div>
                        <div className="flex flex-row items-center gap-4 bg-base-100 w-fit p-3">
                            <p className="font-semibold">Email:</p>
                            <input className="input bg-base-300 font-semibold" disabled value={user.email} aria-disabled="true" />
                        </div>
                        <div className="flex flex-row items-center gap-4 bg-base-100 w-fit p-3">
                            <p className="font-semibold">Phone Number:</p>
                            <input className="input bg-base-300 font-semibold" disabled value={user.phoneNumber} aria-disabled="true" />
                        </div>
                        <div className="flex flex-row items-center gap-4 bg-base-100 w-fit p-3">
                            <p className="font-semibold">State of residence: </p>
                            <input className="input bg-base-300 font-semibold" disabled value={user.state} aria-disabled="true" />
                        </div>
                        


                    </div>


                    <div className="w-full p-3 font-3">

<p className="font-3 text-lg font-bold">Ai Configurations</p>
<div className="mt-3 flex flex-col justify-between w-full">

    <div className=" w-full">

   

        
        <p className="">Operating Area:</p>
        {(user.operatingArea.length === 0) && (
            <p className="text-error text-sm">Required*</p>
        )}
        
        
        <div className="join sm:join-horizontal join-vertical mt-1">
            <input value={operatingInput} maxLength="50" className="input font-1 join-item" placeholder="Fairfax County" onChange={(e) => setOperatingInput(e.target.value)} />
            <button onClick={(e) => {
                if (operatingInput.length < 2) {
                    return
                } else {
                    setUser({...user, operatingArea: [...user.operatingArea, operatingInput]})
                    setOperatingInput("")
                }
            }} className="btn join-item">Add county</button>

        </div>
        
        <div className="mt-2 p-3 min-h-[10vh] bg-base-100 items-center justify-center flex flex-row flex-wrap gap-3 rounded-lg">
            {(user.operatingArea.length === 0) && (
                <div>
                    <p>No areas on file</p>
                </div>
            )}
            {user.operatingArea.map((area,i) => (
                <div className="px-3 select-none rounded-full border-2 border-primary relative">
                    <div className="absolute top-[-10px] right-[-5px] cursor-pointer" onClick={(e) => {
                        setUser((prevUser) => {
    return {
        ...prevUser,
        operatingArea: prevUser.operatingArea.filter((item, index) => index !== i)
    };
});
                    }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4">
  <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clipRule="evenodd" />
</svg>

                    </div>
                    <p>{area}</p>
                </div>
            ))}

       
        </div>
        
    </div>

    <div className="mt-2 flex flex-row items-center gap-4">
        <p className="font-3">Default Ai Name:</p>
        <input className="input" value={aiInput} onChange={(e) => setAiInput(e.target.value)} />
    </div>
    

</div>

</div>

    

                    </div>


                    <div className="bg-base-100 w-full p-3 mt-3">
                        <div className="flex sm:flex-row flex-col gap-4 items-center">
                            <p className="font-3 font-semibold">Subscription:</p>
                            {user.subscription.active ? 
                            <div className="bg-base-300 w-full p-3 flex sm:flex-row flex-col items-center gap-4">
                                <div className="sm:text-left text-center">
                                    <p className="font-1 font-semibold">Basic Plan</p>
                                    <p className="text-sm">Renewed {new Date(user.subscription.renewalDate).toLocaleDateString().substring(0, new Date(user.subscription.renewalDate).toLocaleDateString().length-5)}</p>
                                </div>

                                <div className="group select-none ">

                                    <a href={customerPortalLink + "?prefilled_email=" + user?.email} className="btn">
                                        <p className="flex flex-row items-center gap-2">
                                        Manage Subscription<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  <path d="M11.644 1.59a.75.75 0 0 1 .712 0l9.75 5.25a.75.75 0 0 1 0 1.32l-9.75 5.25a.75.75 0 0 1-.712 0l-9.75-5.25a.75.75 0 0 1 0-1.32l9.75-5.25Z" />
  <path d="m3.265 10.602 7.668 4.129a2.25 2.25 0 0 0 2.134 0l7.668-4.13 1.37.739a.75.75 0 0 1 0 1.32l-9.75 5.25a.75.75 0 0 1-.71 0l-9.75-5.25a.75.75 0 0 1 0-1.32l1.37-.738Z" />
  <path d="m10.933 19.231-7.668-4.13-1.37.739a.75.75 0 0 0 0 1.32l9.75 5.25c.221.12.489.12.71 0l9.75-5.25a.75.75 0 0 0 0-1.32l-1.37-.738-7.668 4.13a2.25 2.25 0 0 1-2.134-.001Z" />
</svg>
                                        </p>
                                        
                                        

                                    </a>
                                    

                                </div>
                                
                                
                                
                               
                                
                            </div> 
                            
                            

                            :
                            <div className="bg-base-300 p-3 flex flex-row items-center gap-4">
                                <div className="">
                                    <p className="font-1 font-semibold">Basic Plan</p>
                                    <p className="text-sm">Not active</p>
                                </div>

                                <div className="btn btn-success" onClick={() => props.setMainNav("Pricing")}>
                                    <p>Manage Subscription</p><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  <path d="M4.5 3.75a3 3 0 0 0-3 3v.75h21v-.75a3 3 0 0 0-3-3h-15Z" />
  <path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-7.5Zm-18 3.75a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z" clipRule="evenodd" />
</svg>

                                </div>
                                
                                
                                
                               
                                
                            </div> 
                            
                            
                            
                            }
                        </div>

                    </div>

                    <div className="btn-accent btn font-bold text-lg mt-2" onClick={changeUser}>
                
                        <p>Submit Changes</p><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  <path fillRule="evenodd" d="M2.25 4.5A.75.75 0 0 1 3 3.75h14.25a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1-.75-.75Zm14.47 3.97a.75.75 0 0 1 1.06 0l3.75 3.75a.75.75 0 1 1-1.06 1.06L18 10.81V21a.75.75 0 0 1-1.5 0V10.81l-2.47 2.47a.75.75 0 1 1-1.06-1.06l3.75-3.75ZM2.25 9A.75.75 0 0 1 3 8.25h9.75a.75.75 0 0 1 0 1.5H3A.75.75 0 0 1 2.25 9Zm0 4.5a.75.75 0 0 1 .75-.75h5.25a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
</svg>


                    </div>
                    
                    
                    
                </div>
                
                
            </div>


        </>
    )
}