import React, {useEffect, useState} from "react"
import Footer from "./Footer"
import Navbar from "./Navbar"
import Loading from "./Loading"
import callApi from "./CallApi"
import { Helmet } from "react-helmet" 





export default function Signup(props) {
    const [loading, setLoading] = useState(false)
    

    const [termsChecked, setTermsChecked] = useState(false)



    // const callApi = (path,method, body) => {
    //     return new Promise(async(resolve) => {
    //         const bodyString = JSON.stringify(body)


        

    //         const response = await fetch(endpoint +  path, {
    //             method: method.toUpperCase(),
    //             mode: "cors",
    //             credentials: "include",
    //             headers: {
    //               "Content-Type": "application/json"
    //             },
    //             body: bodyString
    //         })


    //         resolve(response.json())




    //     })
        
        
        

        

        




    // }


    async function createAccount() {

        if (termsChecked) {


            if (user.code.length < 1) {
                setFormError({
                    id: "code",
                    message: "Enter the code sent to your email"
                })
                return
            }
            setLoading(true)
            await callApi("/register", "POST", user).then((res) => {
                if (res.message === "user already exists") {
                    window.location.replace("/login")
                } else if (res.message === "invalid code") {
                    setFormError({
                        id: "code",
                        message: "Invalid code"
                    })
                } else if (res.message === "invalid request") {
                    setFormError({
                        id: "Terms",
                        message: "Something went wrong"
                    })
                } else if (res.code === "ok") {
                    window.location.replace("/main")
                } else {
                    setFormError({
                        id: "Terms",
                        message: "Something went wrong"
                    })
                }


                setLoading(false)
            }


            )



        } else {
            setFormError({
                id: "Terms",
                message: "You must agree to the terms and conditions."
            })
        }








    }






    const [currentSlide, setCurrentSlide] = useState(0)
    const [formError, setFormError] = useState(
        {
            id: "",
            message: "",
        })

    const [user, setUser] = useState(
        {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            phoneNumber: "",
            code: "",
            state: "",


        })

        const verifyUser = async () => {
            let hasError = false
            
            if (user.firstName.length < 2) {
                hasError = true
                setFormError(
                    {
                        id: "firstName",
                        message: "Enter your first name"
                    })
            }
            if (user.lastName < 2) {
                hasError = true
                setFormError(
                    {
                        id: "lastName",
                        message: "Enter your last name"
                    })
            }
            if (user.email.split("@").length !== 2) {
                hasError = true
                setFormError(
                    {
                        id: "email",
                        message: "Provide a valid email"
                    })
            }
            if (user.phoneNumber.length < 5) {
                hasError = true
                setFormError(
                    {
                        id: "phoneNumber",
                        message: "Provide a valid phone number"
                    })
            }
            if (user.password.length < 8) {
                hasError = true
                setFormError(
                    {
                        id: "password",
                        message: "Password must be at least 8 characters"
                    })
            }
            if (!hasError) {
                setLoading(true)
                await callApi("/sendVerify", "POST", {email: user.email}).then((res) => {
                    if (res.message === "invalid request") {
                        setFormError("email","Invalid email")
                    } else if (res.message === "internal server error") {
                        setFormError("email", "Try again Later")
                    } else if ((res.message === "code already sent") || (res.message === "code sent")) {
                        console.log("good shit")
                        setCurrentSlide(1)
                    } else {
                        setFormError("email", "Try again Later")
                    }

                    setLoading(false)
 

                })
                


                



            }





        }



    const changeUser = (e) => {
        let newValue = e.target.value.trim().toLowerCase()
        newValue = newValue.replace(/\s+/g, '');



        let hasError = false
        let blockChange = false
        if ((e.target.id === "phoneNumber") && (isNaN(Number(newValue)))) {
            hasError = true
            blockChange = true
            setFormError(
                {
                    id: e.target.id,
                    message: "Phone number must only contain numbers."
                })

        }
        if ((e.target.id === "password") && (newValue.length < 8)) {
            hasError = true
            setFormError(
                {
                    id: e.target.id,
                    message: "Password must be at least 8 characters long."
                })

        } 

        if ((e.target.id === "email") && (newValue.split("@").length !== 2)) {
            hasError= true
            setFormError({
                id: "email",
                message: "Enter a valid email"
            })
        }


        if (e.target.id === "state") {
            newValue = newValue.toUpperCase()

        }
        

        if (!hasError) {
            setFormError({id: null, message:null})
            
        }

        if (!blockChange) {
            setUser({...user, [e.target.id]: newValue})
        }
        

        
        


        






    }


    return (

        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Signup • Sniphomes</title>
            <meta name="keywords" content="Real Estate, Leads, Lead Generation, Finding willing buyers, finding willing sellers, sniphome, sniphomes, snip homes, snip home, signup, register" />
            <meta
      name="description"
      content="Sign up for Sniphomes. The better way to generate real estate leads. Sniphomes uses cold calling technology to automate the lead generation process."
    />
        </Helmet>
        


        <div className="grid lg:grid-cols-2 grid-cols-1 w-full min-h-screen">
            <div className="lg:w-3/6 md:w-5/6 md:p-0 p-5 mx-auto bg-base-100 mt-5">
            <a href="/" className="btn !rounded-lg w-fit btn-primary btn-outline">
                <p className="font-1 font-bold text-lg">sniphomes</p>
            </a>


            <div className="md:mt-20 mt-10">
                <p className="font-1 font-semibold text-lg text-primary">Let's make lead generation struggles a thing of the past</p>
                <p className="font-3 font-bold text-4xl"><span className="text-secondary">4x </span>your monthly leads with <span className="text-secondary">10% </span> of the effort. Quality Leads. Everytime.</p>
            </div>

            <div className="lg:mt-40 my-10 bg-base-200 rounded-lg mx-auto w-full p-4">
            <p className="font-3 font-semibold text-2xl my-2">Your future dashboards...</p>
            <div className="stats w-full  md:stats-horizontal stats-vertical lg:stats-vertical shadow bg-base-100 font-3">
                <div className="stat">
                <div class="stat-title font-semibold">Total Leads Generated</div>
    <div class="stat-value">200+</div>
    <div class="stat-desc text-green-500 font-semibold">🔺21% more than last month</div>
                    
                </div>
                <div className="stat">
                <div class="stat-title font-semibold">Total Sales</div>
    <div class="stat-value">15+</div>
    <div class="stat-desc text-green-500 font-semibold">🔺56% more than last month</div>
                    
                </div>
                <div className="stat bg-accent">
                <div class="stat-title font-semibold">Total Commission Generated</div>
    <div class="stat-value">4.2M+</div>
    <div class="stat-desc text-green-500 font-semibold">🔺312% more than last month</div>
                    
                </div>

            </div>

            </div>




            </div>
            

            <div className="bg-base-300 flex flex-col justify-center items-center p-4 text-center relative">
                {(loading) && (
                    <Loading />
                )}
                {(currentSlide === 0) && (
                    <>
                    <p className="font-3 font-semibold text-2xl">Let's work together with <span className="font-bold">sniphomes</span></p>
                <p className="mt-2 font-1 ">No Credit Card Required</p>
                <div className="mt-4  bg-base-100 md:w-3/6 w-full p-4 rounded-lg flex flex-col gap-4 text-left">
                    
                    <div>
                        <input value={user.firstName} onChange={(e) => changeUser(e)} id="firstName" type="text" maxLength="20" className="input w-full input-bordered font-3" placeholder="First Name:" />
                        {(formError.id === "firstName") && (
                            <p className="text-red-500 font-3">{formError.message}</p>
                        )}
                    </div>
                   
                    
                    <div>
                        <input value={user.lastName}  onChange={(e) => changeUser(e)} id="lastName" type="text" maxLength="20" className="input w-full input-bordered font-3" placeholder="Last Name:" />
                        {(formError.id === "lastName") && (
                            <p className="text-red-500 font-3">{formError.message}</p>
                        )}
                    </div>
                    <div>
                        <input value={user.email}  onChange={(e) => changeUser(e)} id="email" type="text" maxLength="100" className="input w-full input-bordered input-primary font-3" placeholder="Email:" />
                        {(formError.id === "email") && (
                            <p className="text-red-500 font-3">{formError.message}</p>
                        )}
                    </div>
                    <div>
                        <input value={user.phoneNumber}  onChange={(e) => changeUser(e)} id="phoneNumber" type="text" maxLength="20" className="input w-full input-bordered input-primary font-3" placeholder="Phone Number:" />
                        {(formError.id === "phoneNumber") && (
                            <p className="text-red-500 font-3 text-sm">{formError.message}</p>
                        )}
                    </div>

                    <div>
                        <input value={user.password}  onChange={(e) => changeUser(e)} id="password" type="password" className="input w-full input-bordered input-primary font-3" placeholder="Password:" />
                        {(formError.id === "password") && (
                            <p className="text-red-500 font-3">{formError.message}</p>
                        )}
                    </div>     
                    
                    <div className="w-full btn-secondary text-base-100 btn" onClick={verifyUser}>
                        <p className="font-3 text-lg text-white">Create your account</p>
                    </div>


                </div>
                    </>

                )}

                {(currentSlide === 1) && (
                    <>
                        <div className="bg-base-100 md:w-4/6 p-4 text-left">
                            
                            <p className="font-3 font-semibold text-2xl">Please verify your email</p>
                            <p className="font-1">We sent a code to <span className="font-bold">{user.email}</span></p>
                            <div className="mt-2 rounded-lg">
                                <div className="flex flex-col gap-2 w-4/6">
                                    <input id="code" value={user.code} type="text" onChange={(e) => changeUser(e)} className="input input-bordered font-1" placeholder="Verification Code" />
                                    {(formError.id === "code") && (
                                        <p className="text-red-500 font-3">{formError.message}</p>
                                    )}

                                </div>
                            </div>
                            
                            <hr className="my-2" />
                            <div className="flex flex-row items-center gap-4 font-3 mt-1">

                            
                            <p className="font-semibold">State:</p>
                            <select id="state" value={user.state} className="mx-2 inline select select-primary font-3 text-sm" onChange={(e) => changeUser(e)}>
	<option value="AL">Alabama</option>
	<option value="AK">Alaska</option>
	<option value="AZ">Arizona</option>
	<option value="AR">Arkansas</option>
	<option value="CA">California</option>
	<option value="CO">Colorado</option>
	<option value="CT">Connecticut</option>
	<option value="DE">Delaware</option>
	<option value="DC">District Of Columbia</option>
	<option value="FL">Florida</option>
	<option value="GA">Georgia</option>
	<option value="HI">Hawaii</option>
	<option value="ID">Idaho</option>
	<option value="IL">Illinois</option>
	<option value="IN">Indiana</option>
	<option value="IA">Iowa</option>
	<option value="KS">Kansas</option>
	<option value="KY">Kentucky</option>
	<option value="LA">Louisiana</option>
	<option value="ME">Maine</option>
	<option value="MD">Maryland</option>
	<option value="MA">Massachusetts</option>
	<option value="MI">Michigan</option>
	<option value="MN">Minnesota</option>
	<option value="MS">Mississippi</option>
	<option value="MO">Missouri</option>
	<option value="MT">Montana</option>
	<option value="NE">Nebraska</option>
	<option value="NV">Nevada</option>
	<option value="NH">New Hampshire</option>
	<option value="NJ">New Jersey</option>
	<option value="NM">New Mexico</option>
	<option value="NY">New York</option>
	<option value="NC">North Carolina</option>
	<option value="ND">North Dakota</option>
	<option value="OH">Ohio</option>
	<option value="OK">Oklahoma</option>
	<option value="OR">Oregon</option>
	<option value="PA">Pennsylvania</option>
	<option value="RI">Rhode Island</option>
	<option value="SC">South Carolina</option>
	<option value="SD">South Dakota</option>
	<option value="TN">Tennessee</option>
	<option value="TX">Texas</option>
	<option value="UT">Utah</option>
	<option value="VT">Vermont</option>
	<option value="VA">Virginia</option>
	<option value="WA">Washington</option>
	<option value="WV">West Virginia</option>
	<option value="WI">Wisconsin</option>
	<option value="WY">Wyoming</option>
                </select>
                {(formError.id === "state") && (
                            <p className="text-red-500 font-3">{formError.message}</p>
                        )}
               
               
                </div>


                            <div className="flex flex-row items-center gap-2">
                            <p className="font-1 mt-1">By signing up, you agree to our <u>terms and conditions</u></p>

                                <input id="Terms" value={termsChecked} type="checkbox" className="checkbox" onChange={(e) => {
                                    if (e.target.checked) {
                                        setTermsChecked(true)
                                    } else {
                                        setTermsChecked(false)
                                    }
                                   
                                }} />
                                

                            </div>
                            {(formError.id === "Terms") && (
                            <p className="text-red-500 font-3">{formError.message}</p>
                        )}
                            <button onClick={createAccount} className="btn btn-secondary text-base-100 mt-2 font-3">
                                Create Account<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  <path fillRule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
</svg>


                            </button>
                            
                            
                            

                            
                            

                        </div>

                       

                    </>

                )}
                
            </div>
            


            
        </div>
        


        {/* Open the modal using document.getElementById('ID').showModal() method */}
{/* <button className="btn hidden">open modal</button> */}
{/* <dialog id="my_modal_1" className="modal w-full h-full">
  <div className="modal-box max-w-full w-2/6  bg-base-100">
    <h3 className="font-bold text-2xl font-3">Create your account</h3>
  
    {(currentSlide === 1) && (
        <>
        <p className="font-1">Basic Information</p>
        <div className="mt-4 p-4 rounded-lg bg-base-300 flex flex-col gap-4">
        <div className="font-1">

        <h6 className="text-lg font-bold">First Name</h6>
        <input className="input w-full" placeholder="Ex: John Doe" />
        </div>
        <div className="font-1">

        <h6 className="text-lg font-bold">Email</h6>
        <input className="input w-full" placeholder="example@email.com" />
        </div>
        
        
        
    </div>

        </>
        


    )}

    {(currentSlide === 2) && (
        <>
            <p className="font-1">Accommodating info</p>

            {/* <p className="mt-4 font-2 font-bold">Complete the following sentence</p> */}
            {/* <div className="p-4 font-3 font-semibold text-2xl bg-base-300 mt-4">
                <p className="inline">I am a real estate agent based in</p>
                <select className="mx-2 inline select font-3 text-sm">
	<option value="AL">Alabama</option>
	<option value="AK">Alaska</option>
	<option value="AZ">Arizona</option>
	<option value="AR">Arkansas</option>
	<option value="CA">California</option>
	<option value="CO">Colorado</option>
	<option value="CT">Connecticut</option>
	<option value="DE">Delaware</option>
	<option value="DC">District Of Columbia</option>
	<option value="FL">Florida</option>
	<option value="GA">Georgia</option>
	<option value="HI">Hawaii</option>
	<option value="ID">Idaho</option>
	<option value="IL">Illinois</option>
	<option value="IN">Indiana</option>
	<option value="IA">Iowa</option>
	<option value="KS">Kansas</option>
	<option value="KY">Kentucky</option>
	<option value="LA">Louisiana</option>
	<option value="ME">Maine</option>
	<option value="MD">Maryland</option>
	<option value="MA">Massachusetts</option>
	<option value="MI">Michigan</option>
	<option value="MN">Minnesota</option>
	<option value="MS">Mississippi</option>
	<option value="MO">Missouri</option>
	<option value="MT">Montana</option>
	<option value="NE">Nebraska</option>
	<option value="NV">Nevada</option>
	<option value="NH">New Hampshire</option>
	<option value="NJ">New Jersey</option>
	<option value="NM">New Mexico</option>
	<option value="NY">New York</option>
	<option value="NC">North Carolina</option>
	<option value="ND">North Dakota</option>
	<option value="OH">Ohio</option>
	<option value="OK">Oklahoma</option>
	<option value="OR">Oregon</option>
	<option value="PA">Pennsylvania</option>
	<option value="RI">Rhode Island</option>
	<option value="SC">South Carolina</option>
	<option value="SD">South Dakota</option>
	<option value="TN">Tennessee</option>
	<option value="TX">Texas</option>
	<option value="UT">Utah</option>
	<option value="VT">Vermont</option>
	<option value="VA">Virginia</option>
	<option value="WA">Washington</option>
	<option value="WV">West Virginia</option>
	<option value="WI">Wisconsin</option>
	<option value="WY">Wyoming</option>
                </select>
                <p className="inline">looking for </p>
                <select className="inline select font-3 text-sm mx-2">
                    <option value="0-5">0-5</option>
                    <option value="6-20">6-20</option>
                    <option value="20+">20+</option>
                </select>
                <p className="inline">leads a month.</p>
            </div>
        
        </>
    )}

    {(currentSlide === 3) && (
        <>
            <p className="font-1">Account Security</p>

            <div className="p-4 font-3 font-semibold bg-base-300 mt-4">
            <div className="font-1">

<h6 className="text-lg font-bold">Password</h6>
<input type="password" maxLength="20" className="input w-full" placeholder="Ex: qwerty" /></div>


        <div className="p-3 bg-base-100 mt-2 rounded-lg">
            <p className="text-lg font-bold font-1">Confirm Email</p>

            <p className="font-3">You stated your email is: <span className="p-1 bg-neutral text-base-100">{email}</span></p>
            <div className="mt-2 btn btn-base-300 bg-base-300 font-3 font-bold">
                <p>Send confirmation email</p>
            </div>

          
            
        </div>

<div className="flex flex-row items-center justify-items-center gap-2 my-2">
            
            <p>Do you agree to let us send you nonessential mail or notifications?</p>
            <input type="checkbox" className="checkbox" />
            </div>


            </div>
            
            
            
        </>
    )}
    
    

    
    <div className="mt-2 flex flex-row items-center justify-items-center gap-2 font-1 font-semibold">
    <p>You agree to our terms of service</p>
    <input type="checkbox" className="checkbox"/>
    </div>
    <div className="flex flex-row gap-2 mt-2 font-1 font-bold">
    <button className="btn btn-outline btn-base-300 font-normal" onClick={() => {
        if (currentSlide > 1) {
            setCurrentSlide(prevSlide => prevSlide-1)
        }
        
    }}>
        Go back<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  <path fillRule="evenodd" d="M9.53 2.47a.75.75 0 0 1 0 1.06L4.81 8.25H15a6.75 6.75 0 0 1 0 13.5h-3a.75.75 0 0 1 0-1.5h3a5.25 5.25 0 1 0 0-10.5H4.81l4.72 4.72a.75.75 0 1 1-1.06 1.06l-6-6a.75.75 0 0 1 0-1.06l6-6a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
</svg>


    </button>
    <button className="btn btn-primary " onClick={(e) => {
        if (currentSlide < 3) {
            setCurrentSlide(prevSlide => prevSlide+1)
        }
        

    }}>
            Continue<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
</svg>

    </button>
    </div> */}
    

    {/* <div className="mt-2 flex flex-row items-center justify-items-center gap-2 font-1 font-semibold">
    <p>You allow us to send promotional mail and mail unrelated to essentials related to our service.</p>
    <input type="checkbox" className="checkbox"/>
    </div> */}
    
    
    
    
    
  {/* </div> */}
{/* // </dialog>  */}



        {/* <Footer /> */}














        </>
    )
}