import React, { useRef, useEffect, useState } from "react"
import Navbar from "./Navbar"
import picture from "./magicpattern-svg-chart-1723508426120.png"
import Footer from "./Footer"
import wave from "./wave.svg"
import callApi from "./CallApi"
import { MdSupportAgent } from "react-icons/md";



export default function Index(props) {
    const [errorMessage, setErrorMessage] = useState({id: "", message: ""})

    const [demoDetails, setDemoDetails] = useState({
        phoneNumber: "",
        email: "",
        name: ""
    })
    const [sendDemo, setSendDemo] = useState(false);
    const sendDemoRequest = () => {
        let passedTest = true
        if (demoDetails.phoneNumber.length<5) {
            setErrorMessage({id: "phoneNumber", message: "Please enter a valid phone number"})
            passedTest = false
        } else if ((demoDetails.email.split("@").length !== 2) || (demoDetails.email.length < 5)) {
            setErrorMessage({id: "email", message: "Please enter a valid email"})
            passedTest = false
        } else if (demoDetails.name.length < 1) {
            setErrorMessage({id: "name", message: "Enter a valid name"})
            passedTest = false
        }

        if (passedTest) {
            setErrorMessage({id: "", message: ""})
            callApi("/requestDemo", "POST", demoDetails).then(() => {
                setSendDemo(true)
            })
        }





    }


    

        

    
    const modifyDemo = (e) => {
        setDemoDetails({...demoDetails, [e.target.id]: e.target.value})
    }




    return (


        <>
            <Navbar />
            


            <div className="hero sm:min-h-[75vh] min-h-[65vh] bg-base-100">

                <div className="hero-content">
                    <div className="flex md:flex-row  w-full h-full items-center justify-items-center gap-x-10">


                    <div className="font-1">
                        <p className="sm:text-7xl text-5xl">Talk to <b>leads that care</b></p>
                        <p className="text-xl">And funnel out the <u>uninterested</u></p>
                        <a href="/signup" className="btn rounded-none btn-accent font-1 font-bold btn-lg text-lg  mt-2">
                            <p>Get started</p>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  <path fillRule="evenodd" d="M15.22 6.268a.75.75 0 0 1 .968-.431l5.942 2.28a.75.75 0 0 1 .431.97l-2.28 5.94a.75.75 0 1 1-1.4-.537l1.63-4.251-1.086.484a11.2 11.2 0 0 0-5.45 5.173.75.75 0 0 1-1.199.19L9 12.312l-6.22 6.22a.75.75 0 0 1-1.06-1.061l6.75-6.75a.75.75 0 0 1 1.06 0l3.606 3.606a12.695 12.695 0 0 1 5.68-4.974l1.086-.483-4.251-1.632a.75.75 0 0 1-.432-.97Z" clipRule="evenodd" />
</svg>


                        </a>

                    </div>
                    

                    <div>

                        <img alt="MainPicture" className="scale-110 w-[40vw] md:block hidden bg-base-300 h-fit p-10 rounded-t-full rounded-br-full object-cover" src={picture} />

                    
                    

                    </div>
                    





                    </div>

                    



                </div>
                

            </div>
            <div className="text-center mx-auto w-fit font-1 font-semibold mb-5 mt-5 bg-base-200 p-5 rounded-full">
                <div className="flex flex-row items-center gap-2">
                <p className="text-lg font-bold">Join </p>
                <div className="avatar-group  -space-x-6 rtl:space-x-reverse" >
                    <div className="avatar border-base-200">
                        <div className="w-12 border-0 rounded-full ">
                        <img src="https://conveyux.com/wp-content/uploads/cervantes.jpg" />
                        </div>

                    </div>
                    <div className="avatar border-base-200">
                        <div className="w-12 border-0 rounded-full ">
                        <img src="https://images.squarespace-cdn.com/content/v1/5fe9e82f1593ec13b3292118/1646623655279-STZB81EZ2S3MO9HOP05B/Kaeya.jpg" />
                        </div>

                    </div>
                    <div className="avatar border-base-200">
                        <div className="w-12 border-0 rounded-full ">
                        <img src="https://img.freepik.com/free-photo/closeup-young-female-professional-making-eye-contact-against-colored-background_662251-651.jpg" />
                        </div>

                    </div>
                    <div className="avatar border-base-200">
                        <div className="w-12 border-0 rounded-full ">
                            <img src="https://ukclippingpath.com/wp-content/uploads/2023/08/Professional-Headshot-Examples.jpg" />
                        </div>

                    </div>
                    <div className="avatar placeholder border-base-200 bg-base-300">
                        <div className="w-12 border-0 rounded-full ">
                        40+
                        </div>

                    </div>


                </div>
                <b className="text-lg">realtors</b>
                </div>
                <div>
                    <div className="flex flex-row mx-auto w-fit mt-1 mb-2">
                    {Array(5).fill(".").map((p,i) => (
                        <>
                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-yellow-500">
  <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
</svg>


                        

                        </>
                    ))}
                        
                    </div>
                    
                </div>
                
                
            </div>
            
                    


            <div className="min-h-[70vh] bg-base-300 flex items-stretch">
             
                <div className="md:w-3/6 w-full md:p-0 p-2 mx-auto mt-24">
                    <p className="font-bold text-4xl font-1">Experience lead valuation on your phone</p>
                    {!(sendDemo) ? 
                        <div className="grid md:grid-cols-2 grid-cols-1 w-full bg-base-100 md:rounded-r-full p-4 mt-4">
                        <div className="bg-neutral font-1  p-4 rounded-sm shadow-md">
                            <p className="font-bold">Receive a call from one of our agents</p>
                            <div className="mt-2 flex flex-col gap-2">
                                <div>
                                    <p>Phone Number</p>
                                    <input id="phoneNumber" maxLength="20" className="input input-sm w-full" placeholder="123456789" value={demoDetails.phoneNumber} onChange={(e) => modifyDemo(e)} />
                                    {(errorMessage.id === "phoneNumber") && (
                                        <>
                                            <p className="text-error">{errorMessage.message}</p>
                                        </>
                                    )}
                                </div>
                                <div>
                                    <p>Email</p>
                                    <input id="email" maxLength="100" className="input input-sm w-full" placeholder="example@email.com" value={demoDetails.email} onChange={(e) => modifyDemo(e)} />
                                    {(errorMessage.id === "email") && (
                                        <>
                                            <p className="text-error">{errorMessage.message}</p>
                                        </>
                                    )}
                                </div>
                                <div>
                                    <p>First Name</p>
                                    <input id="name" maxLength="30" className="input input-sm w-full" placeholder="John" value={demoDetails.name} onChange={(e) => modifyDemo(e)} />
                                    {(errorMessage.id === "name") && (
                                        <>
                                            <p className="text-error">{errorMessage.message}</p>
                                        </>
                                    )}
                                </div>

                                <div className="btn btn-base-300 w-full font-1" onClick={sendDemoRequest}>
                                    <p>Receive call</p>

                                </div>
                            </div>

                        </div>

                        <div className="flex flex-col items-center justify-center">
                        <MdSupportAgent size="12rem" />
                        </div>
                        
                    </div> : 
                    
                    <div className="bg-neutral mt-4 p-4 min-h-[20vh] flex flex-row items-center">
                    <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-12 text-base-100">
  <path fillRule="evenodd" d="M9.315 7.584C12.195 3.883 16.695 1.5 21.75 1.5a.75.75 0 0 1 .75.75c0 5.056-2.383 9.555-6.084 12.436A6.75 6.75 0 0 1 9.75 22.5a.75.75 0 0 1-.75-.75v-4.131A15.838 15.838 0 0 1 6.382 15H2.25a.75.75 0 0 1-.75-.75 6.75 6.75 0 0 1 7.815-6.666ZM15 6.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z" clipRule="evenodd" />
  <path d="M5.26 17.242a.75.75 0 1 0-.897-1.203 5.243 5.243 0 0 0-2.05 5.022.75.75 0 0 0 .625.627 5.243 5.243 0 0 0 5.022-2.051.75.75 0 1 0-1.202-.897 3.744 3.744 0 0 1-3.008 1.51c0-1.23.592-2.323 1.51-3.008Z" />
</svg>
 <div><p className="text-base-100 font-3 mt-2">You will receive a demo call to your phone soon</p></div> </div>
                    
                    

                        
                    </div>
                    
                    }
                    

             
                </div>
                

            </div>


            <div className="min-h-[50vh] bg-base-200  py-20">


                <div className="md:w-3/6 mx-auto">
                    <p className="font-bold sm:text-5xl md:text-6xl text-4xl  font-1 text-center">Best lead generation and valuation</p>
                    <div className="bg-base-300 mt-2 flex flex-row gap-2 p-5 min-h-[40vh] flex-wrap">
                        <div className="bg-base-100 md:max-w-[66%] w-full p-4 h-fit">
                            <div>
                                <p className="font-3 font-bold text-lg">Client Summaries</p>
                                <p className="font-1 font-semibold">Enjoy the use of pre-generated summaries of potiential clients, <br />showing you their real estate needs.</p>
                            </div>
                        </div>
                        <div className="md:max-w-[33%] w-full bg-base-100 p-4">
                            <p className="font-3 font-bold text-lg">Human-Like Calls</p>
                            <p className="font-1 font-semibold">Lead valuation done with human-like voices, boosting their conversion rate.</p>
                        </div>
                        <div className="w-full bg-base-100 p-4">
                            <p className="font-bold font-3 text-lg">Lead Filter</p>
                            <p className="font-1 font-semibold">It automatically filters uninterested clients automatically, only notifying you of the most qualified.</p>
                        </div>
                    </div>
                    
                </div>
                
            </div>

            <div className="bg-base-100 hero sm:min-h-[60vh] min-h-[80vh]">

<div className="hero-content w-full">
    <div className="grid md:grid-cols-2 grid-cols-1 items-center w-full justify-items-center">

        <div className="font-1">
            <p className="font-bold sm:text-6xl text-4xl">Talk to leads that genuinely care</p>
            <p className="text-2xl font-3">and save countless hours today!</p>



            <a href="/signup" className="font-3 text-2xl  btn-lg btn btn-accent mt-8">
                Generate Leads<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
<path fillRule="evenodd" d="M12 2.25a.75.75 0 0 1 .75.75v16.19l2.47-2.47a.75.75 0 1 1 1.06 1.06l-3.75 3.75a.75.75 0 0 1-1.06 0l-3.75-3.75a.75.75 0 1 1 1.06-1.06l2.47 2.47V3a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
</svg>

            </a>
        </div>



        <div className="flex-row gap-2 stroke-neutral text-accent md:flex hidden">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-64">
<path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clipRule="evenodd" />
</svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-64">
<path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clipRule="evenodd" />
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-64">
<path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clipRule="evenodd" />
</svg>


        </div>

    </div>
    

</div>


</div> 
            






            {/* How it works page */}
            {/* <div className="min-h-[40vh] bg-base-300 p-10"> 
            
            <div className="p-2">
                <p className="font-1 font-bold text-3xl">How it works</p>

            </div>

            <div className="grid grid-cols-3 gap-2 p-2">

                <div className="p-10 bg-base-200 border-r-4">
                    <div className="w-12 h-12 rounded-full relative text-center bg-primary font-1 text-white">
                        <p className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] font-bold text-2xl">1</p>
                    </div>
                    <div className="p-1">
                        <p className="font-3 font-bold text-xl">Select your area</p>
                        <p className="font-2">This allows our product to know what areas to target and where to find your qualified leads.</p>
                    </div>

                </div>

                <div className="p-10 bg-base-200 border-r-4">
                    <div className="w-12 h-12 rounded-full relative text-center bg-primary font-1 text-white">
                        <p className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] font-bold text-2xl">2</p>
                    </div>
                    <div className="p-1">
                        <p className="font-3 font-bold text-xl">Create your sales strategy</p>
                        <p className="font-2">Using our new messaging technology, select how to word your lead messages and at what point to redirect them to you.</p>
                    </div>

                </div>

                <div className="p-10 bg-base-200 border-r-4">
                    <div className="w-12 h-12 rounded-full relative text-center bg-primary font-1 text-white">
                        <p className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] font-bold text-2xl">3</p>
                    </div>
                    <div className="p-1">
                        <p className="font-3 font-bold text-xl">Let the leads come</p>
                        <p className="font-2">Watch as your dashboard fills with qualified leads that fall deep into your sales funnel.</p>
                    </div>

                </div>


            </div>
            

                
            </div> */}

           


            {/* <div className="mb-5 p-10 bg-base-100 w-full ">
                <div className="text-center mb-20">
                <p className="font-3 text-4xl font-bold">Not Convinced?</p>
                <p className="font-1 font-semibold text-lg">Here's what we're doing.</p>
                </div>
                
                <div className=" grid grid-cols-2 items-center justify-items-center gap-2">
                <div className="p-4">
                    <h4 className="font-1 text-3xl font-bold">Initial cold-messaging</h4>
                    <div>
                        <p className="font-3 text-lg">Sniphomes uses public <b>data</b> to <b>contact people </b>in your <b className="underline">operating area.</b></p>
                    </div>
                </div>
                
                <div className="w-full h-96 bg-base-100 rounded-lg">
                
                

                </div>
                <div className="w-full h-96 bg-base-100 rounded-lg">

                </div>
                <div className="p-4">
                    <h4 className="font-1 text-3xl font-bold text-right">Sniphome in action</h4>
                    <div>
                        <p className="font-3 text-lg">Sniphomes then <b>engages</b> in a <b>phone call</b> with respondents to filter out <b className="underline">nonqualified leads.</b></p>
                    </div>
                </div>
                <div className="p-4">
                    <h4 className="font-1 text-3xl font-bold text-left">Gather final leads</h4>
                    <div>
                        <p className="font-3 text-lg">Sniphomes <b>analyzes </b>the conversations to determine if they are <b className="underline">qualified leads.</b></p>
                    </div>
                </div>
                <div className="w-full h-96 bg-base-100 rounded-lg"></div>

                <div className="col-span-2 mt-20 text-center bg-base-100 w-4/6 rounded-lg text-neutral py-24">
                    <p className="font-3 text-4xl font-bold">Watch the buyers pile in</p>
                    <p className="font-1 font-2xl">After Sniphomes, you'll be left only with the most willing of all.</p>

                </div>

                </div>
                
                
                
            </div>

            <div className="bg-base-100 hero min-h-[60vh]">

                <div className="hero-content w-full">
                    <div className="grid grid-cols-2 items-center w-full justify-items-center">

                        <div className="font-1">
                            <p className="font-bold text-6xl">Talk to leads that genuinely care</p>
                            <p className="text-2xl font-3">and save countless hours today!</p>



                            <a href="/signup" className="font-3 text-2xl  btn-lg btn btn-accent mt-8">
                                Signup Now<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  <path fillRule="evenodd" d="M12 2.25a.75.75 0 0 1 .75.75v16.19l2.47-2.47a.75.75 0 1 1 1.06 1.06l-3.75 3.75a.75.75 0 0 1-1.06 0l-3.75-3.75a.75.75 0 1 1 1.06-1.06l2.47 2.47V3a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
</svg>

                            </a>
                        </div>



                        <div className="flex flex-row gap-2 stroke-neutral text-accent">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-64">
  <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clipRule="evenodd" />
</svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-64">
  <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clipRule="evenodd" />
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-64">
  <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clipRule="evenodd" />
</svg>


                        </div>

                    </div>
                    

                </div>
                

            </div> */}
            




           
           
            
            

            





            <Footer />



            





        </>
    )
}