import React, {useContext, useEffect, useState} from "react";
import UserContext from "./Context"







export default function AccountWarning(props) {

    const [warningMessage, setWarningMessage] = useState("")

    const user = useContext(UserContext)

    
    useEffect(() => {
        
        if (user?.operatingArea?.length === 0) {
            setWarningMessage("Please finish creating your account.")
        }





    },[user])




    return (


        <>
            {(warningMessage.length === 0) ?
                <>
                </>
            :
            
            <div className="min-h-[5vh] bg-error flex flex-row items-center p-5 text-base-100">
                <p className="font-3 text-lg">{warningMessage}</p>



            </div>
            }
            








        </>
    )
}