import React from "react"

import { FaInstagram, FaTiktok } from "react-icons/fa";



export default function Footer(props) {

    





    return (


        <>

<div className="footer p-12 bg-neutral" data-theme="light">
            <div>
                
                <a href="/" className="font-1 font-bold text-2xl">Sniphomes</a>
                <p className="font-2">A Hernandez Production</p>
               
            </div>
            <div className="font-2 underline-offset-4">
                <a href="/terms" className="underline link">Terms of Service</a>
                <a href="/privacy"  className="underline link">Privacy Policy</a>
                <a href="/signup" className="underline link">Watch our demo</a>
            </div>
            <div className="flex flex-row ">
                <a className="btn btn-base-100 btn-outline bg-base-100 !btn-circle" href="https://www.instagram.com/sniphomes/">
                    <FaInstagram />
                </a>
                
                
            </div>
        



        </div>



        </>
    )
}