import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer"
import { Helmet } from "react-helmet"; 






export default function Terms(props) {




    return (



        <>

<Helmet>
            <meta charSet="utf-8" />
            <title>Terms of Service • Sniphomes</title>
            <meta name="keywords" content="Real Estate, Leads, Lead Generation, Finding willing buyers, finding willing sellers, sniphome, sniphomes, snip homes, snip home, signup, register, terms, terms of service, service, sniphomes terms, sniphome terms" />
            <meta
      name="description"
      content="Terms of Service. Sniphomes The better way to generate real estate leads. Sniphomes uses cold calling technology to automate the lead generation process."
    />
        </Helmet>


            <Navbar />



        <div className=" md:w-4/6 w-full mx-auto my-10 p-6 bg-base-300  font-3  md:rounded-lg">
      <h1 className="text-4xl font-bold text-left mb-8 ">Terms of Service</h1>

      <div className="prose max-w-none flex flex-col gap-4">
        {/* Introduction */}
        <h2 className="text-2xl font-semibold">1. Introduction</h2>
        <p>
          Welcome to Sniphomes. By using our service, you agree to comply with and be bound by the following terms of service. 
          Please review the terms carefully. If you do not agree with these terms, you should not use this service.
        </p>

        {/* User Eligibility */}
        <h2 className="text-2xl font-semibold">2. Eligibility</h2>
        <p>
          Sniphomes is designed for licensed real estate agents. By using this service, you affirm that you are legally 
          authorized to represent yourself as a real estate agent and comply with applicable regulations.
        </p>

        {/* User Data Collection */}
        <h2 className="text-2xl font-semibold">3. Data Collection</h2>
        <div className="">
        <p className="">
          In order to generate leads, we collect and store your personal information such as your name, email address, phone 
          number, and any other relevant information provided. Your data will be handled in accordance with our <a href="/privacy-policy" className="text-primary underline">Privacy Policy</a>
          .
        </p>
        
        </div>
        

        {/* User Responsibilities */}
        <h2 className="text-2xl font-semibold">4. User Responsibilities</h2>
        <p>
          You agree to use Sniphomes responsibly, and in compliance with all local, state, and federal laws. You will not 
          engage in any behavior that misrepresents your real estate credentials or attempts to defraud others through the 
          platform.
        </p>

        {/* Third-party Services */}
        <h2 className="text-2xl font-semibold">5. Third-party Services</h2>
        <p>
          We may use third-party services to enhance our offerings, such as analytics platforms and customer management tools. 
          Sniphomes is not responsible for the actions of third-party service providers.
        </p>

        {/* Content Ownership */}
        <h2 className="text-2xl font-semibold">6. Content Ownership</h2>
        <p>
          Any content you submit through the platform, including your profile information and messages, remains your 
          property. However, you grant Sniphomes a non-exclusive license to use, distribute, and display such content 
          to operate the service.
        </p>

        {/* Account Termination */}
        <h2 className="text-2xl font-semibold">7. Account Termination</h2>
        <p>
          Sniphomes reserves the right to suspend or terminate your account if we determine that you have violated these 
          terms, engaged in fraudulent activity, or used the platform inappropriately. We will notify you in such an event.
        </p>

        {/* Dispute Resolution */}
        <h2 className="text-2xl font-semibold">8. Dispute Resolution</h2>
        <p>
          Any disputes arising from these terms or your use of Sniphomes shall be resolved through binding arbitration, 
          in accordance with the laws of the state of Maryland. Both parties agree to this resolution method as a way 
          to avoid lengthy and costly litigation.
        </p>

        {/* Modifications */}
        <h2 className="text-2xl font-semibold">9. Modifications to the Terms</h2>
        <p>
          Sniphomes reserves the right to modify these terms at any time. If changes are made, users will be notified via 
          email and updates will be posted on our website. Your continued use of the service indicates your acceptance of 
          any updated terms.
        </p>

        {/* Limitation of Liability */}
        <h2 className="text-2xl font-semibold">10. Limitation of Liability</h2>
        <p>
          Sniphomes shall not be liable for any indirect, incidental, or consequential damages arising from your use of the 
          service. This includes, but is not limited to, loss of leads, interruptions in service, or disputes between users.
        </p>

        {/* Governing Law */}
        <h2 className="text-2xl font-semibold">11. Governing Law</h2>
        <p>
          These terms are governed by the laws of the state of Maryland. Any legal proceedings arising from these terms 
          shall take place within the jurisdiction of Maryland courts.
        </p>
      </div>

      
    </div>

      <Footer />








        </>
    )
}