import React, {useContext, useState} from "react";
import UserContext from "./Context";





export default function Greet(props) {

    const user = useContext(UserContext)


    const [isOpened, setIsOpened] = useState(true);



    return (

        <>

        {(isOpened) && (
            <div data-theme="light" className="w-full sm:h-[20vh] shadow-md h-[30vh] border-b-2  sm:px-10 px-5 relative">
            <div className="w-full h-full relative  flex bg-base-100 flex-row items-center">
                <div>
                <p className="font-1 text-3xl font-semibold">Welcome back, {user.name}!</p>
                <p className="font-3 text-lg">Check your inbox for any new leads while you were gone</p>
                </div>
                
            </div>

            <div className="absolute top-8 right-8 btn btn-sm btn-neutral btn-circle !rounded-full" onClick={() => setIsOpened(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
</svg>

                </div>
            
            

        </div>

        )}
        



        </>
    )
}