import React, {useContext} from "react";
import Footer from "./Footer";
import callApi from "./CallApi";

import UserContext from "./Context";

export default function Pricing(props) {

    
    const user  = useContext(UserContext)

    const plan = [
        {
            link: "https://buy.stripe.com/00gg2BcrT8KGaZ2aEE",
            priceId: "price_1Q87ysEIBr27Yq48gSve1Weo",
            price: 200,
            duration: "/month"
        }
        
    ]




    // const openStripe = () => {
    //     // callApi("/create-checkout-session", "POST", {priceId: "price_1Q5DsMEIBr27Yq48zluWhgrg"}).then((res) => {
    //     //     window.location.replace(res.url)
    //     // })


    // }



    return (

        <>

        <div className="w-full bg-base-100">
            <div className="bg-primary text-base-100 sm:px-10 w-full flex sm:flex-row min-h-[30vh] items-center">
      
                
            
                <div className="text-center w-full">
                    <p className="font-1 font-bold text-4xl">Cut out your hassles</p>
                    <p className="font-3 font-semibold text-2xl mt-3">Our clients generate an average of 20 qualified leads per month</p>

                </div>
    
            

            </div>
            <div className="sm:mt-20 p-2 w-full mx-auto flex sm:flex-row flex-col gap-10">

                <div className="sm:p-10 p-4 bg-neutral shadow-sm sm:min-w-96 flex flex-col items-center rounded-sm">
                    <p className="font-1 font-bold text-3xl">Premium Plan</p>
                    <p className="font-2 font-semibold italic text-lg">all the clients you'd ever need</p>
                    <div className="w-full p-4 bg-base-100 mt-4">
                        <div className="font-1 ">
                            <div className="bg-base-300 px-2 py-1">
                            <p className="font-bold text-xl">What we provide</p>
                            <ul className=" flex flex-col gap-2 mt-2 font-3 text-lg">
                                <li className="font-semibold"><span className="mr-2">✅</span>2000+ potential leads contacted</li>
                                <li className="font-semibold"><span className="mr-2">✅</span>Ai-Powered lead follow-up</li>
                                <li className="font-semibold"><span className="mr-2">✅</span>Extensive Lead Information</li>
                                <li className="font-semibold"><span className="mr-2">✅</span>Avoid FTC Cold-Calling fines</li>
                            </ul>

                            </div>
                            
                            <hr className="w-full mt-2 mb-1 border-2 border-base-300 rounded-full" />

                            <div className="w-full px-2 pt-1">
                                <p className="font-1 font-semibold">What we don't provide:</p>
                                <ul className="flex flex-col gap-2 mt-2 font-3 text-lg">
                                <li className="font-semibold"><span className="mr-2">❌</span>Unqualified Leads</li>
                                <li className="font-semibold"><span className="mr-2">❌</span>Reputation Risks</li>
                                <li className="font-semibold"><span className="mr-2">❌</span>Spam/Junk messaging</li>
                                <li className="font-semibold"><span className="mr-2">❌</span>Poor Customer Satisfaction</li>
                            </ul>

                            </div>


                        </div>
                    </div>

                    <div className="w-full mt-2 text-center ">
                        {/* <p className="text-left text-sm font-semibold font-1">100% Satisfaction guarantee or money back</p> */}
                        <a className="btn w-full btn-lg btn-accent" rel="noreferrer" target="_blank" href={plan[0].link + "?prefilled_email=" + user.email}>
                            <p className="font-3 font-bold text-2xl">$200 a month</p>


                        </a>
                        {/* <p className="font-1 ">or</p> */}
                        <p className="mt-1 font-semibold font-1">Roughly  $10.20 per lead</p> 
                        <p className="text-xs font-3">Cancel Anytime</p>

                    </div>


                </div>


                <div className="font-3">

                    <p className="text-3xl font-semibold">Generate over <span className="text-error underline font-bold">20+</span> qualified leads routinely</p>

                    <div>
                        <p className="font-semibold text-lg">Don't believe in our leads? Heres how we gather them.</p>
                    </div>

                    <div className="flex flex-col mt-4 w-full gap-4">

                        <div className="flex-row flex justify-between  bg-base-300 p-4 items-center w-full">
                        <div className="w-5/6">
                            <p className="font-3 text-lg font-bold">Initial Outreach</p>
                            <p className="font-1 font-semibold w-5/6">Using data based on your operating location, we contact locals such as tenants in the area and local homeowners.</p>
                        </div>
                       
                        <p className="font-semibold p-3 w-fit bg-base-100">2000 potential leads reached</p>

                        </div>
                        <div className="flex-row flex justify-between  bg-base-300 p-4 items-center w-full">
                        <div className="w-5/6">
                            <p className="font-3 text-lg font-bold">Lead Valuation</p>
                            <p className="font-1 font-semibold w-5/6">Sniphomes automatically calls those who respond to our initial outreach phase to gather basic info of what they are looking for.</p>
                        </div>
                       
                        <p className="font-semibold p-3 w-fit bg-base-100">20+ leads evaluated</p>

                        </div>


                        <div className="flex-row flex justify-between  bg-base-300 p-4 items-center w-full h-full">
                        <div className="w-5/6">
                            <p className="font-3 text-lg font-bold">Conversion</p>
                            <p className="font-1 font-semibold w-5/6">When Sniphomes accesses that the client is likely to convert, our agents automatically refer them to you.</p>
                        </div>
                       
                        <p className="font-semibold p-3 w-fit bg-base-100">10-20 Clients Secured</p>

                        </div>
                        <div className="text-center flex flex-row justify-center bg-base-300 p-4 h-[20vh] items-center w-full">
                        <div>
                            <p className="font-3 font-bold text-4xl">Secure Sales</p>
                            <p className="font-semibold text-lg">Without all that hassle...</p>
                        </div>
                        
                        </div>
                        


                    </div>
                   
                    
                </div>
            
             





            </div>

            <div className="mt-20 w-full sm:flex hidden p-4 bg-base-300 flex-row justify-items-center justify-around items-center h-[40vh]">
                <div className="">
                    <p className="font-3 font-bold text-5xl">Still not convinced?</p>
                    <div className="font-1 font-bold text-2xl flex sm:flex-row flex-col gap-4 items-center mt-4">
                    <a href="/" className="btn btn-lg btn-primary text-lg font-1">
                        Request a demo
                    </a>
                    or let us
                    <a href="mailto:sniphomes@gmail.com" className="btn btn-lg btn-outline text-lg font-1">
                        contact us
                    </a>
                    
                    
                    </div>
                    <p className="font-1 text-neutral text-sm">will redirect to homepage demo request page*</p>
                </div>
                
                <div></div>
                
                
            </div>
               
        </div>


        </>
    )

}